import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebars from "../sidebars/sidebars";
import DataTable from "react-data-table-component";
import "./detail-representantes.css";
import ModalFormCuentaBancaria from "../representantes/modal-form-cuenta-bancaria";
import axios from "../api/axios";

const RepresentantesDetail = () => {
  const [pending, setPending] = useState(false);
  const [banksAccount, setBanksAccount] = useState([]);
  const { state } = useLocation();

  let name2 = "";
  let email2 = "";
  let cuil2 = "";
  let id2 = "";

  if (state) {
    const { name, email, cuil, id } = state;
    name2 = name;
    email2 = email;
    cuil2 = cuil;
    id2 = id;
  }

  const columns = [
    {
      name: "tipo de cuenta",
      selector: (row) => row.accountType,
      sortable: true,
      reorder: true,
    },
    {
      name: "CBU",
      selector: (row) => row.cbu,
      sortable: true,
      reorder: true,
    },
    {
      name: "Alias",
      selector: (row) => row.alias,
      sortable: true,
      reorder: true,
    },
  ];


  const getBanks = async () => {
    try {
      const response = await axios.get("representantes/" + id2 + "/bank-account/");
      setBanksAccount(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getBanks();
  }, []);


  return (
    <div>
      <Sidebars></Sidebars>

      {!state ? (
        <>
          <h3>no hay datos...</h3>
        </>
      ) : (
        <>
          <div class="container-r">
            <h3>Detalle del representante</h3>
            <div class="rowform-r">
              <div class="col-r">
                <label class="label-r">Representante:</label>
                <p class="form-control-plaintext-r">{name2
 ?? ""}</p>
              </div>

              <div class="col-r">
                <label class="label-r">Cuil:</label>
                <p class="form-control-plaintext-r">{cuil2 ?? ""}</p>
              </div>
            </div>
          </div>
          <div class="container-r">
            <h3>Cuenta bancarias del representante</h3>
            <div className="card text-sm-light mb-3 div-header-info">
              <ModalFormCuentaBancaria title={'sumar nueva cuenta bancaria'} userId={id2} cuil={
                Number(cuil2)
              } getBanks={getBanks}/>
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={banksAccount}
                  highlightOnHove
                  direction="auto"
                  pagination
                  responsive
                  progressPending={pending}
                  highlightOnHover
                ></DataTable>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RepresentantesDetail;
