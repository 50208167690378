import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import add from '../../assets/add proveedor.svg';
import { listColumsRepresentantes } from '../../utils/Columns-representantes';
import { useEncryptedData } from '../../utils/encryptedData';
import axios from '../api/axios';
import Sidebars from '../sidebars/sidebars';

const TableRepresentantes = () => {
  const ls = useEncryptedData();
  const userData = ls.get("token", {
    secret: process.env.REACT_APP_SECRET,
  });
  let isFinanza = false;
  let isContabilidad = false;

  if (userData !== null && userData !== undefined) {
    isFinanza = userData.user.rol.includes("Finanzas");
    isContabilidad = userData.user.rol.includes("Conciliaciones");
  }

  const [pending, setPending] = useState(true);

  const [listRepre, setlistRepre] = useState([]);

  const getListRepre = async () => {
    const optionsRepre = {
      method: "GET",
      url: "representantes/",
    };

    await axios
      .request(optionsRepre)
      .then((response) => {
        setPending(false);
        setlistRepre(response?.data?.data ?? []);
      })
      .catch((error) => {
        toast.error("Error al cargar representantes");
      });
  };

  const columns = listColumsRepresentantes(isFinanza, isContabilidad);

  useEffect(() => {
    getListRepre();
  }, []);

  return (
    <>
      <Sidebars></Sidebars>
      <div
        style={{
          width: '80%',
          margin: 'auto',
        }}
      >
        <br></br>
     
        <div className="card text-sm-light mb-3 div-header-info">
          <div className="card-body">
            <DataTable
              title="Tabla Representantes"
              columns={columns}
              data={listRepre}
              highlightOnHove
              direction="auto"
              pagination
              responsive
              progressPending={pending}
              highlightOnHover
            ></DataTable>
          </div>
        </div>
      </div>
    </>
  );

};

export default TableRepresentantes;
